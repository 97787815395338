import React, { Component } from "react";
import "./styles.scss";

class Policy extends Component {
	componentDidMount() {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	render() {
		return (
			<div className="policy-content">
				<p className="policy-title">سياسة الخصوصية والأمان - مدينة عمان</p>
				<p className="policy-description">بتسجيلكم في موقعنا أو زيارته، تعتبرون قد وافقتم على جمع واستخدام بياناتكم الشخصية.</p>
				<div className="policy-content">
					<p className="content-title">البيانات الشخصية</p>
					<p className="content-value">
						تُجمع البيانات الشخصية وتُحفظ بشكل آمن بمراعاة التشريعات النافذة، ويتم التعامل معها دائماً بسرية تامة. تُبرر هذه الإجراءات للحفاظ على أمان وسرية سجلات ومعلومات العملاء، ولحمايتها من أي تهديدات أو
						مخاطر، ولمنع الوصول غير المصرح به، مما يحد من أي آثار ضارة أو إزعاج للعملاء.
					</p>
				</div>
				<div className="policy-content">
					<p className="content-title">أنواع البيانات المجمعة</p>
					<p className="content-value">
						نقوم بجمع معلومات مباشرة خلال فترة التعاون، تشمل البيانات الشخصية مثل الاسم، تاريخ الميلاد، البريد الإلكتروني، العنوان، وتفاصيل مصرفية. كما نقوم بجمع معلومات غير مباشرة مثل عنوان الجهاز، نوع
						المتصفح، ونظام التشغيل. تُستخدم هذه المعلومات الغير مباشرة للتحقق وتعزيز الأمان، وتشمل عناوين IP، ومعرف الجهاز (MAC id) والكوكي.
					</p>
				</div>
				<div className="policy-content">
					<p className="content-title">إدارة البيانات</p>
					<p className="content-value">
						سلامة بياناتكم هي هدفنا الرئيسي. لا نقوم بتوزيع أو بيع بيانات عملائنا، ونكشف عنها لأطراف ثالثة فقط إذا كان ذلك ضروريًا لتقديم الخدمات. يمكننا أن نشارك المعلومات مع أطراف ثالثة لتحسين خدماتنا وأداء
						الوظائف الأخرى.
					</p>
					<p className="content-value">نحن قد نبرم اتفاقيات مع أطراف ثالثة لتحسين خدمة العملاء والأداء العام، مع الالتزام بالحفاظ على سرية المعلومات.</p>
				</div>
				<div className="policy-content">
					<p className="content-title">التسويق والاتصالات</p>
					<p className="content-value">
						نحن قد نتواصل معكم إلكترونيًا أو بوسائل أخرى لتزويدكم بمعلومات حول منتجاتنا، ومواد تسويقية، أو تقييم طلبات الخدمة. يمكنكم إلغاء الاشتراك في أي وقت عن طريق النقر على الرابط المتاح في نهاية رسائل
						البريد الإلكتروني.
					</p>
				</div>
				<div className="policy-content">
					<p className="content-title">أمان المعلومات في مدينة عمان</p>
					<p className="content-value">
						نلتزم باتخاذ جميع التدابير الأمنية اللازمة لحماية بياناتكم في مدينة عمان. نستخدم تقنيات تشفير متقدمة لضمان سرية المعلومات المرسلة والمستلمة عبر الموقع. كما نقدم دورات تدريبية دورية لموظفينا للحفاظ
						على أعلى مستويات الوعي بأمان المعلومات.
					</p>
				</div>
				<div className="policy-content">
					<p className="content-title">حقوق الفرد</p>
					<p className="content-value">تُخصص حقوق الفرد في مدينة عمان، بما في ذلك الحق في الوصول إلى بياناته الشخصية وتصحيحها وحذفها. يمكنكم ممارسة هذه الحقوق عند الضرورة بالاتصال بفريق الدعم الخاص بنا.</p>
				</div>
				<div className="policy-content">
					<p className="content-title">مشاركة المعلومات مع الجهات الحكومية</p>
					<p className="content-value">
						قد نقوم بمشاركة بعض المعلومات مع الجهات الحكومية في مدينة عمان إذا كان ذلك مطلوبًا بموجب التشريعات السارية، أو إذا كان ذلك ضروريًا لحماية حقوقنا وحقوق عملائنا، أو للتصدي للتهديدات الأمنية.
					</p>
				</div>
				<div className="policy-content">
					<p className="content-title">تحديثات لسياسة الخصوصية</p>
					<p className="content-value">نحتفظ بحق تحديث سياسة الخصوصية هذه بشكل دوري لضمان توافقها مع التطورات القانونية والتكنولوجية. سيتم إشعاركم بأي تغييرات هامة في سياسة الخصوصية عبر وسائل مناسبة.</p>
				</div>
			</div>
		);
	}
}

export default Policy;
