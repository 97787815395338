export function scrollTo(e) {
	const section = document.querySelector(e);

	if (section) {
		window.scrollTo({
			top: section.offsetTop - 90,
			behavior: "smooth",
		});
	}

	const scrollInterval = setInterval(() => {
		const section = document.querySelector(e);

		if (section) {
			clearInterval(scrollInterval);
			window.scrollTo({
				top: section.offsetTop - 90,
				behavior: "smooth",
			});
		}
	}, 500);
}
