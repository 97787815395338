/* eslint-disable react-hooks/exhaustive-deps */
import "./App.scss";
import { Route, Switch, Redirect } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import ConfigData from "./config.json";
import axios from "axios";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Footer from "./components/Footer";
import Header from "./components/Header";

//pages
import Home from "./pages/Home";
import { useEffect, useState } from "react";
import Policy from "./pages/Policy";

function App() {
	const [whatsappNumber, setWhatsappNumber] = useState(null);

	const checkBlackList = async () => {
		var Buffer = require("buffer/").Buffer;
		const username = ConfigData.Authorization.username;
		const password = ConfigData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		const response = await axios({
			method: "POST",
			url: ConfigData.server_URI + "/common/is-in-blacklist",
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		if (response.data === "banned") {
			window.location.replace("https://google.com");
		}
	};

	const getPhoneNumber = async () => {
		var Buffer = require("buffer/").Buffer;
		const username = ConfigData.Authorization.username;
		const password = ConfigData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		const request = {
			column: "campaign_name",
			value: `'${ConfigData.campaignName}'`,
			tableName: "campaigns_phone_numbers",
			selectValues: "phone_number",
		};
		const response = await axios({
			method: "POST",
			url: ConfigData.server_URI + "/common/get-entries",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		setWhatsappNumber(response.data[0]?.phone_number || null);
	};

	useEffect(() => {
		checkBlackList();
		getPhoneNumber();
	}, []);

	return (
		<div className="App">
			<Header />
			{whatsappNumber && (
				<div className="whatsapp-sticky">
					<a href={"https://wa.me/" + whatsappNumber + "?text=" + whatsappNumber} target="_blank" rel="noopener noreferrer">
						<WhatsAppIcon className="whatsapp-icon" />
					</a>
				</div>
			)}
			<div className="app-body">
				<Switch>
					<Route exact path="/" render={() => <Home />} />
					<Route exact path="/privacy" render={() => <Policy />} />
					<Redirect to="/" />
				</Switch>
			</div>
			<Footer />
			<SnackbarProvider maxSnack={5} />
		</div>
	);
}

export default App;
