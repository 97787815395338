/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { scrollTo } from "../../assets/helpers/navHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { withTranslation } from "react-i18next";

function Header() {
	const history = useHistory();

	const [openMobileMenu, setOpenMobileMenu] = useState(false);
	const [navButtons] = useState([
		{
			label: "الرئيسية",
			onClick: () => {
				history.push("/");
				scrollTo("#home");
				toggleMobileMenu(false);
			},
		},
		{
			label: "من نحن",
			onClick: () => {
				history.push("/");
				scrollTo("#about_us");
				toggleMobileMenu(false);
			},
		},
		{
			label: "خدماتنا",
			onClick: () => {
				history.push("/");
				scrollTo("#our_services");
				toggleMobileMenu(false);
			},
		},
		{
			label: "سجل شكوتك",
			onClick: () => {
				history.push("/");
				scrollTo("#contact_us");
				toggleMobileMenu(false);
			},
		},
	]);

	const toggleMenu = () => {
		setOpenMobileMenu(!openMobileMenu);
	};

	const onMenuBGClick = (e) => {
		if (e.target.closest(".nav-menu")) {
			return;
		}
		setOpenMobileMenu(!openMobileMenu);
	};

	const toggleMobileMenu = (value) => {
		setOpenMobileMenu(value);
	};

	const onLogoClick = () => {
		history.push("/");
		scrollTo("#home");
	};

	return (
		<div className="header">
			<div className="header-content">
				<div className="navigations">
					<MenuIcon className="mobile-menu-btn" onClick={toggleMenu} />
					<div className={openMobileMenu ? "nav-menu-bg opened" : "nav-menu-bg"} onClick={onMenuBGClick}>
						<div className="nav-menu">
							<CloseIcon className="close-mobile-menu-btn" onClick={() => toggleMobileMenu(false)} />
							{navButtons.map((navButton, i) => (
								<button key={i} className="nav-button" onClick={navButton.onClick}>
									{navButton.label}
								</button>
							))}
						</div>
					</div>
				</div>
				<p className="header-logo" onClick={onLogoClick}>
					تقديم <label style={{ color: "var(--tint)" }}>بلاغ</label>
				</p>
			</div>
		</div>
	);
}

export default withTranslation()(Header);
