/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { scrollTo } from "../../assets/helpers/navHelper";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

function Footer(props) {
	const history = useHistory();

	const navButtons = [
		{
			label: "من نحن",
			onClick: () => {
				history.push("/");
				scrollTo("#about_us");
			},
		},
		{
			label: "سياسة الخصوصية والشروط والأحكام",
			onClick: () => history.push("/privacy"),
		},
	];

	return (
		<div className="footer">
			<div className="footer-content">
				<div className="footer-navigations">
					{navButtons.map((navButton, i) => (
						<button key={i} className="footer-nav-button" onClick={navButton.onClick}>
							{navButton.label}
						</button>
					))}
				</div>

				<div className="copyright-wrapper">
					<p className="copyright-message">© {new Date().getFullYear()} قسم الشكاوي</p>
				</div>
			</div>
		</div>
	);
}

export default withTranslation()(Footer);
