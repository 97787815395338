import React, { useState } from "react";
import "./style.scss";
import { withTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { enqueueSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { scrollTo } from "../../assets/helpers/navHelper";
import configData from "../../config.json";
import axios from "axios";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

function Home() {
	const history = useHistory();

	const [state, setState] = useState({
		loadingSubmitContactUs: false,
		contactThankYou: {
			done: false,
			id: null,
		},
	});

	const generateRandomNumber = (length = 9) => {
		return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));
	};

	const onSubmitContactUs = async (e) => {
		e.preventDefault();
		const fullName = e.target.full_name.value;
		const phoneNumber = e.target.phone_number.value;
		const email = e.target.email.value;
		const message = e.target.message.value;
		if (fullName?.length < 2 || fullName?.length > 45) {
			enqueueSnackbar(`يجب أن يكون طول الاسم بين 2 و45 حرفًا. (حاليًا: ${fullName.length})`, { variant: "error" });
			changeBorderColor(e, "full_name", "contact-us-input-wrapper", "var(--red)");
			return;
		}
		if (phoneNumber?.length < 8 || phoneNumber?.length > 15) {
			enqueueSnackbar(`يجب أن يكون طول رقم الهاتف بين 8 و15 حرفًا. (حاليًا: ${phoneNumber.length})`, { variant: "error" });
			changeBorderColor(e, "phone_number", "contact-us-input-wrapper", "var(--red)");
			return;
		}
		if (email?.length < 6 || email?.length > 45) {
			enqueueSnackbar(`يجب أن يكون طول البريد الالكتروني بين 6 و45 حرفًا. (حاليًا: ${email.length})`, { variant: "error" });
			changeBorderColor(e, "email", "contact-us-input-wrapper", "var(--red)");
			return;
		}
		if (message?.length < 2 || message?.length > 512) {
			enqueueSnackbar(`يجب أن يكون طول الشكوى بين 2 و512 حرفًا. (حاليًا: ${message.length})`, { variant: "error" });
			changeBorderColor(e, "message", "contact-us-textarea-wrapper", "var(--red)");
			return;
		}
		setState({ ...state, loadingSubmitContactUs: true });
		const order_number = generateRandomNumber();
		const request = {
			full_name: fullName,
			location: "",
			phone_number: phoneNumber,
			email: email,
			details: message,
			owner_id: process.env.REACT_APP_OWNER_ID || 1,
			order_number: order_number,
			source: configData.campaignName,
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		await axios({
			method: "POST",
			url: configData.server_URI + "/shakwa/add-new-lead",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		const contactThankYou = {
			done: true,
			id: order_number,
		};
		setState({ ...state, loadingSubmitContactUs: false, contactThankYou: contactThankYou });
		e.target.reset();
	};

	const changeBorderColor = (e, name, wrapper, color) => {
		const element = name ? e.target[name].closest("." + wrapper) : e.target.closest("." + wrapper);
		element.style.borderColor = color;
	};

	return (
		<div className="home-page" id="home">
			<div className="home-bg-image" />
			<div className="home-section transparent center vertical" style={{ paddingTop: 70 }}>
				<div className="home-section-body vertical intro">
					<p className="company-name">تقديم بلاغ</p>
				</div>
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 1200 366" preserveAspectRatio="none">
					<g className="pix-waiting pix-animate animated" data-anim-type="fade-in-up" data-anim-delay="700">
						<polygon fill="url(#divider-18625-bottom-overlay-layer-3)" points="0 240 1200 0 1200 366 0 366"></polygon>
					</g>
					<g className="pix-waiting pix-animate animated" data-anim-type="fade-in-up" data-anim-delay="600">
						<polygon fill="url(#divider-18625-bottom-overlay-layer-2)" points="0 300 1200 60 1200 366 0 366"></polygon>
					</g>
					<polygon fill="#f8f5ff" points="0 360 1200 120 1200 366 0 366"></polygon>
					<defs>
						<linearGradient id="divider-18625-bottom-overlay-layer-3" x1="0%" y1="0%" x2="100%" y2="0%">
							<stop offset="0%" stopColor="rgba(74,87,226,0.5)"></stop>
							<stop offset="100%" stopColor="rgba(239,170,222,0.2)"></stop>
						</linearGradient>
						<linearGradient id="divider-18625-bottom-overlay-layer-2" x1="0%" y1="0%" x2="100%" y2="0%">
							<stop offset="0%" stopColor="rgba(139,92,249,0.8)"></stop>
							<stop offset="100%" stopColor="rgba(58,242,162,0.8)"></stop>
						</linearGradient>
					</defs>
				</svg>
			</div>
			<div className="home-section" id="about_us">
				<div className="home-section-body vertical">
					<div className="section-left" style={{ maxWidth: 700, margin: "auto" }}>
						<p className="section-pre-title">سلطنة عمان</p>
						<p className="section-title-text">
							من <b>نحن</b>
						</p>
						<p className="section-text-body">
							نحن مؤسسة مختصة بحماية حقوق المستهلكين ومكافحة الغش التجاري. نقدم خدمات إلكترونية متميزة تهدف إلى تمكين المستهلكين من تقديم بلاغاتهم بسهولة وفعالية بشأن حالات الاحتيال والغش في المعاملات
							التجارية.
						</p>
						<p className="section-text-title">رسالتنا:</p>
						<p className="section-text-body">نهدف من خلال خدمتنا إلى تحقيق العدالة التجارية وضمان تجربة تسوق آمنة وموثوقة للمستهلكين. نحن شريككم المخلص في مكافحة الاحتيال وتحقيق حقوق المستهلك.</p>
						<br />
						<br />
						<Button
							type="submit"
							className="my-button-style"
							variant="contained"
							onClick={() => {
								history.push("/");
								scrollTo("#contact_us");
							}}
						>
							قدم بلاغك الان
						</Button>
					</div>
				</div>
			</div>
			<div className="home-section" id="our_services">
				<div className="home-section-body flip">
					<div className="section-left">
						<p className="section-title">
							<b className="section-title-text">خدماتنا</b>
						</p>
						<div className="our-services">
							<div className="our-service">
								<p className="service-title">الإبلاغ عن الاحتيال عبر الإنترنت:</p>
								<div className="service-dot" />
								<p className="service-description">نستقبل بلاغات الاحتيال عبر الإنترنت من المستهلكين، سواء تعلق الأمر بشراء منتجات أو خدمات من مصادر غير موثوقة، أو بعدم استلام المنتجات المدفوعة.</p>
							</div>
							<div className="our-service">
								<p className="service-title">استرجاع الأموال المسروقة:</p>
								<div className="service-dot" />
								<p className="service-description">نقدم الدعم لاستعادة الأموال المسروقة نتيجة الاحتيال بعد تقديم الشكوى وإجراء التحريات القانونية اللازمة.</p>
							</div>
							<div className="our-service">
								<p className="service-title">الإبلاغ عن الغش التجاري:</p>
								<div className="service-dot" />
								<p className="service-description">نرحب ببلاغات المستهلكين حول الغش التجاري، مثل شراء عقارات من شركات وهمية أو منتجات غير مطابقة للمواصفات.</p>
							</div>
							<div className="our-service">
								<p className="service-title">التعامل مع عدم استلام المنتجات:</p>
								<div className="service-dot" />
								<p className="service-description">نساعد المستهلكين الذين يواجهون مشاكل في استلام المنتجات التي تم طلبها من المتاجر الإلكترونية أو البائعين غير الشرعيين.</p>
							</div>
							<div className="our-service">
								<p className="service-title">الإبلاغ عن عمليات الاستقدام الوهمية:</p>
								<div className="service-dot" />
								<p className="service-description">يمكنكم الإبلاغ عن عمليات الاستقدام الوهمية التي تتضمن عدم استرجاع الأموال بشكل غير قانوني.</p>
							</div>
							<div className="our-service">
								<p className="service-title">تقديم الاستشارات القانونية والفنية:</p>
								<div className="service-dot" />
								<p className="service-description">بالإضافة إلى استقبال البلاغات، نوفر استشارات قانونية وفنية للمستهلكين لمساعدتهم على فهم حقوقهم وخطوات حل النزاعات التجارية.</p>
							</div>
						</div>
						<p className="service-final">نحن نعمل على توفير خدماتنا بمهنية عالية وتعاونية لضمان حماية حقوق المستهلكين وتحقيق العدالة في المعاملات التجارية.</p>
					</div>
				</div>
			</div>
			<div className="home-section transparent center" id="contact_us">
				<div className="home-section-body vertical intro">
					<form className="contact-us-form" onSubmit={onSubmitContactUs}>
						<p className="contact-us-title">لتقديم البلاغ الرجاء تعبئة الحقول التالية</p>
						{state.contactThankYou.done ? (
							<div>
								<p className="contact-thanku-message">شكرا لك على التواصل</p>
								<p className="contact-thanku-message">
									تم استلام رسالتكم، ونحن نقدر استفساركم.
									<br /> رقم الشكوى الخاص بكم هو <b style={{ color: "var(--tint)" }}>{state.contactThankYou.id}</b>
									<br /> سيقوم فريقنا بالرد عليكم قريبًا.
								</p>
							</div>
						) : (
							<>
								<div className="contact-us-input-wrapper">
									<PersonIcon className="contact-us-input-icon" />
									<input
										className="contact-us-input"
										name="full_name"
										type="text"
										placeholder="الاسم الكامل"
										autoComplete="off"
										onChange={(e) => changeBorderColor(e, null, "contact-us-input-wrapper", null)}
										required
									/>
								</div>
								<div className="contact-us-input-wrapper">
									<PhoneIcon className="contact-us-input-icon" />
									<input
										className="contact-us-input"
										name="phone_number"
										type="number"
										inputMode="numeric"
										placeholder="رقم الهاتف"
										autoComplete="off"
										onChange={(e) => changeBorderColor(e, null, "contact-us-input-wrapper", null)}
										required
										onWheel={(e) => e.target.blur()}
									/>
								</div>
								<div className="contact-us-input-wrapper">
									<EmailIcon className="contact-us-input-icon" />
									<input
										className="contact-us-input"
										name="email"
										type="email"
										placeholder="البريد الالكتروني"
										autoComplete="off"
										onChange={(e) => changeBorderColor(e, null, "contact-us-input-wrapper", null)}
										required
									/>
								</div>
								<div className="contact-us-textarea-wrapper">
									<textarea
										className="contact-us-textarea"
										name="message"
										placeholder="اكتب مشكلتك باختصار - بسطر او اثنين"
										autoComplete="off"
										onChange={(e) => changeBorderColor(e, null, "contact-us-textarea-wrapper", null)}
										required
									/>
								</div>
								<p className="accept-rights">
									من خلال تقديم هذا النموذج فإنك توافق على{" "}
									<NavLink to="/privacy" style={{ color: "var(--tint)" }}>
										سياسة الخصوصية والشروط والأحكام
									</NavLink>
								</p>
								<Button type="submit" className="my-button-style" variant="contained" disabled={state.loadingSubmitContactUs || state.contactThankYou.done}>
									سجّل لنعاود الاتصال بك
								</Button>
							</>
						)}
					</form>
				</div>
			</div>
		</div>
	);
}

export default withTranslation()(Home);
